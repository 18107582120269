import React, { useRef } from "react";
import classNames from "classnames";
import * as Accordion from "@radix-ui/react-accordion";
import { ReactComponent as ChevronIcon } from "../assets/chevron-right.svg";

const AccordionItem = React.forwardRef(({ children, className, ...props }, forwardedRef) => (
  <Accordion.Item
    className={classNames(
      "mt-px overflow-hidden first:mt-0 first:rounded-t last:rounded-b  focus-within:relative focus-within:z-10 ",
      className
    )}
    {...props}
    ref={forwardedRef}
  >
    {children}
  </Accordion.Item>
));

const AccordionTrigger = React.forwardRef(({ children, className, ...props }, forwardedRef) => (
  <Accordion.Header className="flex">
    <Accordion.Trigger
      className={classNames(
        "text-start group flex font-semibold h-[50px] flex-1 items-center justify-between cursor-pointer bg-white px-5 text-[14px] leading-normal text-violet11 outline-none hover:bg-mauve2 border-t border-t-transparent-black-10 rounded-lg",
        className
      )}
      {...props}
      ref={forwardedRef}
    >
      <p className="max-w-[96%]">{children}</p>
      <ChevronIcon
        className="text-violet10 rotate-90 transition-transform duration-300 ease-[cubic-bezier(0.87,_0,_0.13,_1)] group-data-[state=open]:rotate-[270deg] w-5"
        aria-hidden
      />
    </Accordion.Trigger>
  </Accordion.Header>
));

const AccordionContent = React.forwardRef(({ children, className, ...props }, forwardedRef) => (
  <Accordion.Content
    className={classNames(
      "overflow-hidden bg-mauve2 text-[13px] text-monochrome-400 data-[state=closed]:animate-slideUp data-[state=open]:animate-slideDown",
      className
    )}
    {...props}
    ref={forwardedRef}
  >
    <div className="px-5 pb-[15px]">{children}</div>
  </Accordion.Content>
));
const AccordionComponent = () => {
  const refs = useRef({});

  const handleScrollToContent = (value) => {
    if (value && refs.current[value]) {
      setTimeout(() => {
        refs.current[value]?.scrollIntoView({ behavior: "smooth", block: "center" });
      }, 300);
    }
  };

  return (
    <Accordion.Root
      className="w-full rounded-md bg-mauve6 max-h-[75dvh] overflow-scroll"
      type="single"
      defaultValue="item-1"
      onValueChange={handleScrollToContent}
      collapsible
    >
      <AccordionItem
        value="item-1"
        className="!-mt-1.5"
        ref={(el) => (refs.current["item-1"] = el)}
      >
        <AccordionTrigger className="border-t-transparent h-[55px]">
          Apa itu program Nyambel Jadi Untung atau Undian Berhadiah ABC Sambal Nusantara?
        </AccordionTrigger>
        <AccordionContent>
          <p>
            ABC Sambal Nusantara Undian Berhadiah #NyambelJadiUntung merupakan program promosi yang
            diselenggarakan oleh ABC Sambal Nusantara, cukup dengan mengirimkan kode unik yang
            ditemukan di kemasan khusus "Undian Berhadiah", peserta berkesempatan memenangkan hadiah
            mulai dari Rp5.000 hingga Rp500.000 dalam bentuk E-Wallet (OVO, GoPay, Dana, atau
            ShopeePay) atau Pulsa Prabayar ke nomor Anda.
          </p>
          <br />
          <p>Program akan berlangsung hingga 30 April 2025 pukul 23.59 WIB</p>
        </AccordionContent>
      </AccordionItem>

      <AccordionItem value="item-2" ref={(el) => (refs.current["item-2"] = el)}>
        <AccordionTrigger>Bagaimana cara mengikuti program ini?</AccordionTrigger>
        <AccordionContent>
          <div className="space-y-2">
            <p>
              Berikut cara mengikuti program ABC Sambal Nusantara Undian Berhadiah
              #NyambelJadiUntung:
            </p>
            <ul className="list-decimal pl-5">
              <li>Beli ABC Sambal Nusantara Terasi dengan kemasan khusus "Undian Berhadiah"</li>
              <li>Temukan kode unik di dalam kemasan dan scan QR Code di belakang kemasan</li>
              <li>
                Anda akan diarahkan ke WhatsApp ABC Ahlinya Buat Citarasa, ikuti petunjuknya dan
                Anda akan diminta untuk memasukan kode unik
              </li>
              <li>Kode unik yang telah dinyatakan valid, akan diundi secara langsung</li>
              <li>
                Jika Anda dinyatakan menang, Anda akan diminta untuk melengkapi data yang dibutuhkan
                oleh penyelenggara
              </li>
              <li>
                Jika Anda kurang beruntung, Anda dapat mencoba kembali dengan kode unik dalam
                kemasan yang berbeda
              </li>
            </ul>
            <p>
              1 (satu) Kode unik hanya dapat digunakan 1X (satu kali) Berikut cara mengikuti program
              ABC Sambal Nusantara Undian Berhadiah #NyambelJadiUntung: 1. Beli ABC Sambal Nusantara
              Terasi dengan kemasan khusus "Undian Berhadiah" 2. Temukan kode unik di dalam kemasan
              dan scan QR Code di belakang kemasan 3. Anda akan diarahkan ke WhatsApp ABC Ahlinya
              Buat Citarasa, ikuti petunjuknya dan Anda akan diminta untuk memasukan kode unik 4.
              Kode unik yang telah dinyatakan valid, akan diundi secara langsung 5. Jika Anda
              dinyatakan menang, Anda akan diminta untuk melengkapi data yang dibutuhkan oleh
              penyelenggara 6. Jika Anda kurang beruntung, Anda dapat mencoba kembali dengan kode
              unik dalam kemasan yang berbeda 1 (satu) Kode unik hanya dapat digunakan 1X (satu
              kali)
            </p>
          </div>
        </AccordionContent>
      </AccordionItem>

      <AccordionItem value="item-3" ref={(el) => (refs.current["item-3"] = el)}>
        <AccordionTrigger>Apa syarat saya untuk mengikuti program ini?</AccordionTrigger>
        <AccordionContent>
          <div className="space-y-2">
            <p>
              Program ini berlaku untuk seluruh Warga Negara Indonesia dengan Nomor Induk
              Kependudukan yang sah dan berdomisili di Indonesia.
            </p>
            <ul className="list-disc pl-5">
              <li>
                Memiliki nomor ponsel dengan WhatsApp aktif dan tidak mengubah nomor selama
                mengikuti program ini.
              </li>
              <li>
                Mengirimkan foto kode unik yang ada dalam kemasan khusus "Undian Berhadiah" ABC
                Sambal Nusantara Terasi ke akun WhatsApp ABC Ahlinya Buat Citarasa.
              </li>
              <li> Simpan kemasan dengan kode unik sebagai bukti validasi pemenang.</li>
              <li>
                Bukan merupakan karyawan serta anggota keluarga dari karyawan PT ABC Heinz
                dan/ataupun afiliasinya, agensi, pemasok dan/atau vendor yang terkait dengan
                penyelenggaraan program program ini.
              </li>
            </ul>
          </div>
        </AccordionContent>
      </AccordionItem>
      <AccordionItem value="item-4" ref={(el) => (refs.current["item-4"] = el)}>
        <AccordionTrigger>Produk apa saja yang berlaku untuk program ini?</AccordionTrigger>
        <AccordionContent>
          <p>
            ABC Sambal Nusantara Terasi kemasan sachet 18g dengan kemasan khusus "Undian Berhadiah"
          </p>
          <div className="w-full flex justify-center">
            <img
              src="https://cdn.kar-tini.id/sambal_05f62e04f5.png"
              alt="Img"
              className="w-[60%]"
            />
          </div>

          <br />
          <p>Kode unik ini hanya terdapat di kemasan khusus "Undian Berhadiah".</p>
        </AccordionContent>
      </AccordionItem>
      <AccordionItem value="item-5" ref={(el) => (refs.current["item-5"] = el)}>
        <AccordionTrigger>Apa saja syarat untuk memenangkan hadiah?</AccordionTrigger>
        <AccordionContent>
          <div className="space-y-2">
            <p>Syarat dan Ketentuan Pemenang:</p>
            <ul className="list-decimal pl-5">
              <li>
                Pemenang hanya perlu mengirimkan data identitas diri termasuk KTP dan foto kemasan
                produk dengan kode unik yang dapat terbaca dengan jelas. Proses ini diperlukan untuk
                memastikan bahwa pemenang sah dan memenuhi syarat, serta sebagai pemenuhan kewajiban
                pajak terkait hadiah (ditanggung oleh Penyelenggara).
              </li>
              <li>
                Pemenang akan diminta untuk melengkapi bukti dengan mengirimkan foto kemasan dengan
                kode unik yang dinyatakan menang.
              </li>
              <li>
                Hadiah tidak dapat dipindahtangankan (pastikan NAMA sesuai dengan KTP dan nomor
                WhatsApp terhubung dengan e-wallet atau nomor prabayar).
              </li>
            </ul>
            <p>
              Untuk informasi lebih lanjut mengenai persyaratan klaim hadiah, Anda dapat mempelajari
              Syarat & Ketentuan program ini.
            </p>
            <p>
              Pastikan Anda menyimpan dan mengirimkan data yang sesuai untuk mendapatkan hadiahnya
              ya!
            </p>
            <p>
              Hati-hati penipuan yang beratasnamakan PT Heinz ABC Indonesia! Seluruh bentuk
              komunikasi pada program ini hanya melalui WhatsApp ABC Ahlinya Buat Citarasa di
              +62-811-1050-0515.
            </p>
          </div>
        </AccordionContent>
      </AccordionItem>
      <AccordionItem value="item-6" ref={(el) => (refs.current["item-6"] = el)}>
        <AccordionTrigger>Kapan hadiah akan diterima?</AccordionTrigger>
        <AccordionContent>
          <p>
            Hadiah akan dikirimkan secara langsung maksimal dalam 1x24 jam setelah data pemenang
            berhasil tervalidasi. Pemenang akan diberikan tautan untuk mengecek status hadiah secara
            langsung.
          </p>
        </AccordionContent>
      </AccordionItem>
      <AccordionItem value="item-7" ref={(el) => (refs.current["item-7"] = el)}>
        <AccordionTrigger>Apakah ada biaya yang harus ditanggung oleh pemenang?</AccordionTrigger>
        <AccordionContent>
          <p>
            Hati-hati penipuan yang beratasnamakan PT Heinz ABC Indonesia! PT Heinz ABC Indonesia
            tidak memungut biaya apapun termasuk pajak pemenang (pajak pemenang akan ditanggung oleh
            penyelenggara).
          </p>
        </AccordionContent>
      </AccordionItem>
      <AccordionItem value="item-8" ref={(el) => (refs.current["item-8"] = el)}>
        <AccordionTrigger>Bisakah saya menang lebih dari satu kali?</AccordionTrigger>
        <AccordionContent>
          <p>
            Pemenang dapat berkesempatan untuk menang lebih dari satu kali. Semakin banyak ABC
            Sambal Nusantara Terasi dengan kemasan khusus "Undian Berhadiah" yang Anda beli, semakin
            besar peluang Anda untuk menang.
          </p>
        </AccordionContent>
      </AccordionItem>
      <AccordionItem value="item-9" ref={(el) => (refs.current["item-9"] = el)}>
        <AccordionTrigger>Bagaimana cara untuk cek status hadiah?</AccordionTrigger>
        <AccordionContent>
          <p>
            Setelah pemenang menyelesaikan pembicaraan dan mengirimkan data yang diperlukan,
            pemenang akan diberikan tautan untuk dapat cek status hadiah secara langsung.
          </p>
        </AccordionContent>
      </AccordionItem>
      <AccordionItem value="item-10" ref={(el) => (refs.current["item-10"] = el)}>
        <AccordionTrigger className="h-[55px]">
          Saya mendapatkan notifikasi bahwa saya menang, kapan batas untuk mengambil hadiahnya?
        </AccordionTrigger>
        <AccordionContent>
          <p>
            Pemenang diberikan waktu maksimal 1x24 jam untuk memberikan data pemenang yang butuhkan.
            Jika masih dalam batas waktu tersebut, pemenang masih dapat melengkapi data untuk
            selanjutnya diverifikasi sebelum dapat mengklaim hadiah. Jika Pemenang tidak mengklaim
            hadiah dalam waktu maksimal 1x24 jam, pemenang akan dinyatakan gugur.
          </p>
        </AccordionContent>
      </AccordionItem>
      <AccordionItem value="item-11" ref={(el) => (refs.current["item-11"] = el)}>
        <AccordionTrigger>Apakah pemenang akan dihubungi pihak penyelenggara?</AccordionTrigger>
        <AccordionContent>
          <p>
            Tidak, segala bentuk komunikasi akan dilakukan hanya melalui WhatsApp ABC Ahlinya Buat
            Citarasa. Anda tidak diperlukan untuk menghubungi pihak ABC selain melalui WhatsApp
            resmi tersebut.
          </p>
          <p>Hati-hati penipuan yang beratasnamakan PT Heinz ABC Indonesia!</p>
        </AccordionContent>
      </AccordionItem>
      <AccordionItem value="item-12" ref={(el) => (refs.current["item-12"] = el)}>
        <AccordionTrigger>Bagaimana jika saya mengalami kendala kode unik?</AccordionTrigger>
        <AccordionContent>
          <div className="space-y-2">
            <ul className="list-decimal pl-5">
              <li>
                Kode unik tidak valid: Kode unik yang telah Anda kirimkan akan melalui validasi
                secara sistem. Jika kode tersebut salah atau sudah digunakan, kode tersebut dianggap
                tidak valid. Pastikan Anda memeriksa kembali kode unik Anda untuk menghindari
                kesalahan
              </li>
              <li>
                Kemasan rusak: Jika kode unik masih dapat terbaca secara lengkap dan jelas, Anda
                tetap dapat mengikuti program ini. Penyelenggara tidak bertanggung jawab atas
                kemasan yang rusak oleh konsumen. Pastikan Anda membuka kemasan dengan hati-hati.
              </li>
              <li>
                Kemasan hilang: Foto bungkus kemasan dengan kode unik merupakan salah satu syarat
                pemenang, pemenang akan dinyatakan gugur dan tidak dapat melanjutkan proses hadiah
                tanpa mengikuti syarat dan ketentuan
              </li>
            </ul>
            <p>
              Untuk informasi lebih lanjut mengenai kendala kode unik, Anda dapat mempelajari Syarat
              & Ketentuan program ini.
            </p>
          </div>
        </AccordionContent>
      </AccordionItem>
      <AccordionItem value="item-13" ref={(el) => (refs.current["item-13"] = el)}>
        <AccordionTrigger className="h-[55px]">
          Apa yang harus dilakukan jika saya tidak menerima balasan dari WhatsApp ABC Ahlinya Buat
          Citarasa?
        </AccordionTrigger>
        <AccordionContent>
          <p>
            Pastikan Anda tidak mengalami kendala jaringan selama mengikuti program ini. Jika Anda
            mengalami kendala, Anda dapat menghubungi layanan pelanggan ABC di WhatsApp ABC Ahlinya
            Buat Citarasa di +62-811-1050-0515.
          </p>
        </AccordionContent>
      </AccordionItem>
      <AccordionItem value="item-14" ref={(el) => (refs.current["item-14"] = el)}>
        <AccordionTrigger>
          Apakah saya dapat mengubah no HP dan KTP yang didaftarkan?
        </AccordionTrigger>
        <AccordionContent>
          <p>Nomor HP dan identitas diri tidak dapat dirubah.</p>
        </AccordionContent>
      </AccordionItem>

      <AccordionItem value="item-15" ref={(el) => (refs.current["item-15"] = el)}>
        <AccordionTrigger className="h-[55px]">
          Bagaimana jika saya memenangkan hadiah, tetapi tidak menerima notifikasi?
        </AccordionTrigger>
        <AccordionContent>
          <p>
            Pastikan Anda telah menyelesaikan seluruh percakapan di WhatsApp ABC Ahlinya Buat
            Citarasa. Penyelenggara akan menginformasikan Anda jika Anda menang, jadi penting untuk
            mengikuti semua langkah dalam percakapan hingga akhir untuk menerima informasi dan
            hadiah Anda.
          </p>
        </AccordionContent>
      </AccordionItem>
      <AccordionItem value="item-16" ref={(el) => (refs.current["item-16"] = el)}>
        <AccordionTrigger className="h-[77px]">
          Saya sudah mengirimkan dokumen yang diperlukan, tapi dokumennya salah. Apakah saya bisa
          mengirim ulang dokumen yang benar?
        </AccordionTrigger>
        <AccordionContent>
          <p>
            Data pemenang akan melalui proses validasi dan pemenang akan mendapatkan statusnya dalam
            waktu maksimal 1x24jam. Jika terdapat kekurangan atau ketidaksesuaian data, pemenang
            akan diminta untuk mengirimkan kembali data yang dibutuhkan.
          </p>
        </AccordionContent>
      </AccordionItem>
    </Accordion.Root>
  );
};

export default AccordionComponent;

import React from "react";
import ModalBasic from ".";

const ModalInstruction = ({ open, setOpen, icon }) => {
  return (
    <ModalBasic
      name="tnc"
      open={open}
      setOpen={setOpen}
      title="Syarat & Ketentuan"
      enClose={true}
      icon={icon}
    >
      <img
        src="https://cdn.kar-tini.id/Frame_16_1_f0e1d19964.png"
        alt="ABC Sambal Nusantara Instruction"
      />
    </ModalBasic>
  );
};

export default ModalInstruction;

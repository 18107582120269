import React from "react";
import ModalBasic from ".";

const ModalTnC = ({ open, setOpen, icon }) => {
  return (
    <ModalBasic
      name="tnc"
      open={open}
      setOpen={setOpen}
      title="Syarat & Ketentuan"
      enClose={true}
      icon={icon}
    >
      <div className="max-h-[70vh] overflow-scroll">
        <h1 className="text-base font-bold mb-4">
          Syarat dan Ketentuan ABC Sambal Nusantara Undian Berhadiah #NyambelJadiUntung
        </h1>
        <p className="mb-4 text-xs text-gray-700">1 Januari – 30 April 2025</p>
        <p className="mb-4 text-xs text-gray-700">
          Promosi ABC Sambal Nusantara Undian Berhadiah #NyambelJadiUntung (selanjutnya disebut
          sebagai ”Promosi”) adalah program promosi yang diselenggarakan oleh PT Heinz ABC Indonesia
          selaku penyelenggara (selanjutnya disebut sebagai “Penyelenggara”) atas merek dagang
          produk Penyelenggara, yakni ABC Sambal Nusantara. Proses penyelenggaraan promosi atas
          produk terkait akan didukung oleh PT Missi Idea Selaras dan PT Kartini Otomasi Indonesia
          selaku pihak yang ditunjuk oleh Penyelenggara sebagai agency/operator penyelenggara undian
          (selanjutnya disebut sebagai “Agency Penyelenggara”).
        </p>
        <p className="mb-4 text-xs text-gray-700">
          Pada Promosi ini, pihak yang berpartisipasi (atau selanjutnya disebut sebagai ”Peserta”),
          berkesempatan memenangkan hadiah langsung berupa Voucher Digital dari mulai Rp 5.000,-
          (lima ribu rupiah) hingga Rp 500.000,- (lima ratus ribu rupiah). Sehubungan dengan
          diselenggarakannya promosi ini, Peserta berkewajiban secara saksama membaca dan memahami
          syarat dan ketentuan sebagaimana dimuat dalam dokumen ini (”S&K Undian Berhadiah ABC
          Sambal Nusantara”) sebelum berpartisipasi dalam promosi ini. Dengan mengikuti promosi ini,
          Peserta dianggap telah setuju terhadap S&K Undian Berhadiah ABC Sambal Nusantara.
        </p>
        <h2 className="text-base font-semibold mt-6 mb-2">1. Periode Promosi</h2>
        <p className="mb-4 text-xs text-gray-700">
          Promosi dimulai tanggal sejak tanggal 1 Januari 2025 hingga tanggal 30 April 2025 (dengan
          batas waktu maksimal yaitu pada pukul 23.59 WIB pada akun WhatsApp ABC Ahlinya Buat
          Citarasa) (selanjutnya disebut sebagai “Periode Promosi”).
        </p>
        <h2 className="text-base font-semibold mt-6 mb-2">2. Syarat Kualifikasi Peserta:</h2>
        <ul className="list-[lower-alpha] text-xs space-y-3 list-inside mb-4 text-gray-700">
          <li>
            Warga Negara Indonesia memiliki Nomor Induk Kependudukan terdaftar pada Direktorat
            Jenderal Kependudukan dan Pencatatan Sipil (Dukcapil) yang sah dan masih berlaku, serta
            berdomisili di wilayah Republik Indonesia;
          </li>
          <li>
            Memiliki nomor HP dengan nomor WhatsApp aktif yang dapat dihubungi untuk keperluan
            Promosi, dan tidak mengubah nomor HP/WhatsApp selama periode Promosi;
          </li>
          <li>
            Bersedia dan sanggup tunduk dan mematuhi S&K Undian Berhadiah ABC Sambal Nusantara, dan
            tidak melakukan kecurangan atau tindakan melanggar hukum apapun dalam atau terkait
            keikutsertaannya pada Promosi;
          </li>
          <li>
            Menyimpan kemasan asli dengan kode unik dalam kondisi dapat tervalidasi oleh
            Penyelenggara, sebagai syarat validasi apabila terpilih menjadi pemenang undian;
          </li>
          <li>
            Apabila menjadi pemenang Promosi, bersedia untuk diumumkan identitas diri dan
            keikutsertaannya pada Promosi, termasuk diberitahu ke otoritas pajak terkait pajak
            hadiah.
          </li>
        </ul>
        <h2 className="text-base font-semibold mt-6 mb-2">3. Mekanisme dan Hadiah:</h2>
        <ol className="list-[lower-alpha] text-xs space-y-4 list-inside mb-4 text-gray-700">
          <li>
            Peserta yang membeli ABC Sambal Nusantara dengan kemasan khusus "Undian Berhadiah" akan
            mendapatkan kode unik di dalam kemasan.
          </li>
          <li>
            Kode unik dikirimkan ke akun WhatsApp ABC Ahlinya Buat Citarasa melalui salah satu cara
            berikut ini:
            <ul className="list-disc list-inside pl-6">
              <li>WhatsApp ke nomor +62 811-1050-0515; atau</li>
              <li>Scan kode QR yang tertera pada kemasan.</li>
            </ul>
          </li>
          <li>
            Peserta yang telah melakukan registrasi kode unik ke WhatsApp ABC Ahlinya Buat Citarasa
            akan mendapatkan notifikasi resmi maksimal 3x24 jam terkait keabsahan Peserta dan
            keikutsertaan Peserta dalam Promosi.
          </li>
          <li>
            Jika beruntung, peserta akan mendapatkan hadiah langsung berupa Voucher Digital sebesar
            Rp 5.000,- hingga Rp 500.000,-.
            <ul className="list-disc list-inside pl-6">
              <li>
                Hadiah akan dikirimkan langsung ke nomor HP pemenang yang aktif pada saat melakukan
                registrasi kode unik ke WhatsApp ABC Ahlinya Buat Citarasa dalam waktu 1x24 jam.
              </li>
              <li>
                Hadiah Voucher Digital dapat berupa voucher pulsa prabayar atau e-wallet (OVO,
                GoPay, Dana, atau ShopeePay) sesuai pilihan pemenang.
              </li>
            </ul>
          </li>
          <li>
            Pajak hadiah dari seluruh pemenang akan ditanggung oleh Penyelenggara. Pemenang dari
            Promosi wajib mengirimkan data diri berupa nama lengkap, Nomor Induk Kependudukan (NIK)
            yang sah sesuai KTP dan foto atas KTP pemenang undian berhadiah.
          </li>
        </ol>
        <h2 className="text-base font-semibold mt-6 mb-2">4. Penentuan Pemenang:</h2>
        <ul className="list-[lower-alpha] text-xs space-y-4 list-inside mb-4 text-gray-700">
          <li>
            Agency Penyelenggara yang ditunjuk Penyelenggara dapat melakukan verifikasi terhadap
            Peserta melalui telepon. Pastikan nomor HP yang diberikan oleh peserta masih aktif dan
            setiap saat dapat dihubungi.
          </li>
          <li>
            Pemenang akan dinyatakan gugur apabila pemenang bersangkutan:
            <ul className="list-disc list-inside pl-6">
              <li>
                tidak melakukan konfirmasi atau tidak dapat dihubungi olehpenyelenggara untuk
                keperluan verifikasi hadiah dalam waktu 1x24jam;
              </li>
              <li>
                tidak dapat memberikan kelengkapan dokumen pendukung padasaat proses verifikasi
                sesuai dengan ketentuan mekanisme promosi;
              </li>
              <li>
                melakukan kecurangan atau tindakan melanggar hukum apapundalam atau terkait
                keikutsertaannya pada Promosi; atau
              </li>
              <li>
                tidak dapat menunjukkan dan/atau mengirimkan foto kartu identitasdiri berupa KTP
                yang terdaftar pada Dukcapil dengan nama yangsama dengan yang tertera dalam data
                yang dikirimkan.
              </li>
            </ul>
          </li>
          <li>
            Hadiah tidak dapat dipindahtangankan, dialihkan kepada pihak manapun selain pemenang,
            diperjualbelikan, atau ditukar dengan barang lain untuk alasan apapun.
          </li>
        </ul>
        <h2 className="text-base font-semibold mt-6 mb-2">5. Ketentuan Lain:</h2>
        <ul className="list-[lower-alpha] text-xs space-y-4 list-inside mb-4 text-gray-700">
          <li>Keputusan Penyelenggara bersifat final dan tidak dapat diganggu gugat.</li>
          <li>
            Penyelenggara berhak atas pertimbangannya sendiri untuk mendiskualifikasi peserta yang
            diduga bertindak dengan cara apapun yang dianggap oleh Penyelenggara sebagai suatu
            pelanggaran atas S&K Undian Berhadiah ABC Sambal Nusantara atau bertindak dengan cara
            apapun yang dianggap mengganggu kegiatan promosi oleh Penyelenggara.
          </li>
          <li>
            Penyelenggara tidak bertanggung jawab terhadap kualitas jaringan dan perangkat
            komunikasi Peserta, input yang terlambat, tidak lengkap, atau kehilangan maupun
            kerusakan atas kemasan berisi kode unik karena alasan teknis atau lainnya. Peserta
            bertanggung jawab terhadap keakuratan dari rincian kontak yang diberikannya.
          </li>
          <li>Penyelenggara tidak bertanggung jawab atas kemasan berisi kode unik yang rusak.</li>
          <li>
            Penyelenggara tidak bertanggung jawab atas setiap kerugian, kehilangan, atau kerusakan
            yang diderita oleh setiap Peserta dalam mengikuti program atau sebagai akibat dari
            keikutsertaan pada Promosi, penerimaan suatu Hadiah atau tindakan pelanggaran hukum
            pihak ketiga terkait Promosi.
          </li>
          <li>
            Baik Penyelenggara atau siapapun yang terkait secara profesional dengan Penyelenggara
            untuk melaksanakan Program, tidak bertanggung jawab atas:
            <ul className="list-disc pl-6">
              <li>
                Setiap kesalahan atau ketidakakuratan upload kode unik, atau atas setiap kerusakan,
                kegagalan atau kesalahan pada transmisi data elektronik.
              </li>
              <li>
                Setiap akses yang tidak sah terhadap, atau pencurian, atau perusakan kode unik atau
                kemasan pada tahap apapun dalam pelaksanaan Program ini.
              </li>
              <li>
                Kegagalan jalur komunikasi, apapun penyebabnya, sehubungan dengan setiap peralatan,
                perangkat, sistem, jaringan, jalur, satelit, server, komputer, atau provider yang
                digunakan terkait Promosi.
              </li>
              <li>
                Tidak dapat diaksesnya atau tidak tersedianya jaringan/signal sehubungan dengan
                pengiriman WhatsApp dalam rangka keikutsertaan dalam Promosi.
              </li>
            </ul>
          </li>
          <li>
            Pihak Penyelenggara berhak mempublikasikan nama dan keterangan pemenang untuk keperluan
            komunikasi atau iklan, termasuk testimoni yang berkenaan dengan Promosi ini di berbagai
            media baik cetak, elektronik, maupun digital tanpa memberi kontribusi keuangan apapun
            pada pemenang.
          </li>
          <li>
            Penyelenggara memiliki hak untuk mengubah S&K Undian Berhadiah ABC Sambal Nusantara jika
            dianggap perlu atas pertimbangannya sendiri, tanpa perlu ada pemberitahuan terlebih
            dahulu ke pihak manapun.
          </li>
          <li>
            Penyelenggara atas pertimbangannya sendiri berhak membatalkan, menunda dan melakukan
            perubahan apapun atas Promosi ini dalam hal terjadinya keadaan di luar kendali atau
            force majeure, untuk hal mana, hadiah tersebut akan hangus dan Penyelenggara tidak akan
            bertanggung jawab, dan bebas dari segala macam tuntutan atau klaim di kemudian hari.
          </li>
        </ul>
        <h2 className="text-base font-semibold mt-6 mb-2">6. Legal Disclaimer</h2>
        <ol className="list-[lower-alpha] text-xs space-y-4 list-inside mb-4 text-gray-700">
          <li>
            Untuk keperluan Promosi, Peserta dengan ini menyetujui pemberian data pribadi Peserta
            secara sukarela dan tanpa paksaan apa pun, dan menyetujui bahwa Penyelenggara dapat
            menggunakan dan melakukan pemrosesan terhadap data pribadi Peserta yang tercakup pada
            Informasi Rahasia yang akan Peserta serahkan kepada Penyelenggara melalui Agency
            Penyelenggara, sesuai dengan ketentuan Undang-Undang Nomor 27 Tahun 2022 tentang
            Pelindungan Data Pribadi beserta perubahannya dan peraturan turunannya (“UU PDP”),
            peraturan perundang-undangan lainnya yang berlaku, serta ketentuan internal
            Penyelenggara.
          </li>
          <li>
            Peserta dengan ini menyatakan bahwa Peserta telah membaca,memahami dan menyetujui S&K
            Promosi, dan hak-hak Peserta sebagaisubjek data pribadi, di antaranya hak untuk: (i)
            mendapatkan informasitentang kejelasan identitas, dasar kepentingan hukum,
            tujuanpermintaan dan penggunaan data pribadi Peserta serta akuntabilitas
            dariPenyelenggara; (ii) melengkapi, memperbarui, dan/atau memperbaikikesalahan dan/atau
            ketidakakuratan data pribadi Peserta; (iii) mendapatkan akses dan memperoleh salinan
            data pribadi yang pesertaberikan Penyelenggara; (iv) mengakhiri pemrosesan,
            menghapus,dan/atau memusnahkan data pribadi peserta yang ada padaPenyelenggara; (v)
            menarik kembali persetujuan pemrosesan yang telahpeserta berikan kepada Penyelenggara;
            (vi) mengajukan keberatan atastindakan pengambilan keputusan secara otomatis oleh
            Penyelenggara;(vii) menunda atau membatasi pemrosesan secara proporsional sesuaidengan
            keperluan pelaksanaan , dan (viii) menggugat dan menerimaganti rugi atas pelanggaran
            pemrosesan data pribadi olehPenyelenggara.
          </li>
          <li>
            Peserta dapat menggunakan hak-hak Peserta di atas dengan menghubungi Penyelenggara pada
            kontak yang telah disediakan pada S&K Promosi.
          </li>
          <li>
            Kecuali ditentukan lain dalam S&K Promosi ini, Penyelenggara menyatakan dan menjamin
            bahwa Informasi Rahasia Peserta yang diberikan melalui WhatsApp ABC Ahlinya Buat
            Citarasa dan dikirimkan untuk registrasi kode unik akan:
            <ol className="list-[lower-roman] pl-6 space-y-2">
              <li>
                Dijaga sebagai informasi yang bersifat rahasia dan oleh karenanya informasi tersebut
                tidak akan dijual, ditukar, dipublikasikan, dibuka, atau diberikan kepada pihak lain
                dalam bentuk apapun, termasuk melalui fotokopi atau penggandaan, tanpa persetujuan
                tertulis dari Peserta terlebih dahulu.
              </li>
              <li>Digunakan oleh Penyelenggara hanya untuk keperluan Promosi.</li>
            </ol>
          </li>
          <li>
            Penyelenggara dapat memberikan Informasi Rahasia tanpa persetujuan terlebih dahulu dari
            Peserta dalam hal informasi tersebut:
            <ol className="list-[lower-roman] pl-6 space-y-2">
              <li>
                Diperlukan oleh peraturan perundang-undangan yang berlaku atau oleh keputusan
                peraturan, kebijakan, atau instruksi otoritas berwenang.
              </li>
              <li>
                Kepada pihak ketiga lainnya yang telah mendapatkan persetujuan tertulis sebelumnya
                dari Peserta selaku pemilik data, dengan ketentuan bahwa Penyelenggara akan
                memberikan pemberitahuan tertulis kepada Peserta yang menjelaskan isi dari Informasi
                Rahasia yang akan diberikan, kepada siapa Informasi Rahasia akan diberikan.
                Pemberitahuan tersebut akan dibuat oleh Penyelenggara sebelum dilakukannya pemberian
                Informasi Rahasia tersebut.
              </li>
            </ol>
          </li>
          <li>
            Penyelenggara menghargai ketertarikan dan kunjungan Peserta ke platform ini. Dengan
            menggunakan dan atau mengakses platform ini, Peserta dengan ini menerima seluruh isi S&K
            Promosi yang tertulis di sini.
          </li>
          <li>
            Program ini tidak dipungut biaya apapun. Hati-hati penipuan. Penyelenggara tidak
            bertanggung jawab atas penipuan yang mengatasnamakan Penyelenggara.
          </li>
          <li>
            Apabila Peserta menyetujui, data pribadi Peserta akan dikelola oleh Penyelenggara untuk
            tujuan pemasaran selama kurun waktu 10 (sepuluh) tahun.
          </li>
          <li>
            Dokumen tunduk dan akan ditafsirkan sesuai dengan hukum Negara Republik Indonesia.
            Setiap perselisihan akan diselesaikan dalam kekuasaan hukum/wilayah hukum eksklusif
            pengadilan di Indonesia, tetapi Penyelenggara memiliki hak penuh untuk mengajukan
            gugatan kepada Peserta di pengadilan negara lain di mana kekuasaan/wilayah hukum dapat
            dibentuk, atau otoritas penegak hukum lain yang terkait.
          </li>
          <li>
            Dengan menjadi Peserta dalam Program promosi ini, maka Peserta dianggap telah membaca,
            memahami, dan menyetujui semua S&K Promosi ini. Untuk keterangan lebih lanjut, silakan
            hubungi WhatsApp resmi Heinz ABC Indonesia di +62 811-1050-0515 atau Call Center agency
            penyelenggara di +62 811-9790-0700 (Jam Operasional adalah Senin – Minggu, 08.00 – 20.00
            WIB).
          </li>
        </ol>
      </div>
    </ModalBasic>
  );
};

export default ModalTnC;

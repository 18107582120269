import React from "react";
import Countdown from "react-countdown";

const CountdownComponent = () => {
  const targetDate = new Date(2025, 0, 1);

  const renderer = ({ days, hours, minutes, seconds }) => {
    return (
      <div className="flex items-center justify-between">
        <div className="bg-transparent-white-10 flex flex-col items-center justify-between py-2 w-[20%] rounded-md">
          <p className="text-lg font-bold">{String(days).padStart(2, "0")}</p>
          <p className="text-monochrome-0">Hari</p>
        </div>
        <p className="text-lg font-bold">:</p>
        <div className="bg-transparent-white-10 flex flex-col items-center justify-between py-2 w-[20%] rounded-md">
          <p className="text-lg font-bold">{String(hours).padStart(2, "0")}</p>
          <p>Jam</p>
        </div>
        <p className="text-lg font-bold">:</p>
        <div className="bg-transparent-white-10 flex flex-col items-center justify-between py-2 w-[20%] rounded-md">
          <p className="text-lg font-bold">{String(minutes).padStart(2, "0")}</p>
          <p>Menit</p>
        </div>
        <p className="text-lg font-bold">:</p>
        <div className="bg-transparent-white-10 flex flex-col items-center justify-between py-2 w-[20%] rounded-md">
          <p className="text-lg font-bold">{String(seconds).padStart(2, "0")}</p>
          <p>Detik</p>
        </div>
      </div>
    );
  };
  return (
    <Countdown
      date={targetDate}
      intervalDelay={0}
      precision={3}
      renderer={renderer}
      autoStart={true}
    />
  );
};

export default CountdownComponent;

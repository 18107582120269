import React, { useEffect, useState } from "react";
import { ReactComponent as FacebookIcon } from "../assets/fb.svg";
import { ReactComponent as InstagramIcon } from "../assets/instagram.svg";
import { ReactComponent as TiktokIcon } from "../assets/tiktok.svg";
import { ReactComponent as WhatsappIcon } from "../assets/wa.svg";
import { ReactComponent as InfoCircle } from "../assets/info-circle.svg";
import { ReactComponent as QuestionIcon } from "../assets/file-question-02.svg";
import { ReactComponent as AnnotationIcon } from "../assets/annotation-question.svg";
import { ReactComponent as ChevronIcon } from "../assets/chevron-right.svg";
import ModalTnC from "./Modal/ModalTnC";
import ModalInstruction from "./Modal/ModalInstruction";
import ModalFAQ from "./Modal/ModalFAQ";
import { useLocation } from "react-router-dom";
import useWindowSize from "../hooks/useWindowSize";
import CountdownComponent from "./Countdown";

const Details = () => {
  const { width } = useWindowSize();
  const imageUrl = {
    landingImage: "https://cdn.kar-tini.id/sambal_05f62e04f5.png",
    logo: "https://cdn.kar-tini.id/logo_7593430a7a.png",
    landingText: "https://cdn.kar-tini.id/landing_text_c575679ed5.png",
    landingImageMobile: "https://cdn.kar-tini.id/sambal_mobile_2e9bd85834.png",
    landingTextMobile1: "https://cdn.kar-tini.id/text_1_bdd9e95838.png",
    landingTextMobile2: "https://cdn.kar-tini.id/text_2_6670c92cb4.png",
    textNyambel: "https://cdn.kar-tini.id/nyambel_7c24ebff79.png",
  };
  const { search } = useLocation();
  const [showTnc, setShowTnc] = useState(false);
  const [showFaq, setShowFaq] = useState(false);
  const [showInstruction, setShowInstruction] = useState(false);

  useEffect(() => {
    console.log(search);
    if (search) {
      if (search === "?menu=frequently-asked-questions") {
        setShowFaq(true);
      } else if (search === "?menu=terms-and-condition") {
        setShowTnc(true);
      } else if (search === "?menu=cara-ikutan") {
        setShowInstruction(true);
      }
    }
  }, [search]);
  return (
    <>
      <ModalTnC open={showTnc} setOpen={setShowTnc} icon={<QuestionIcon className="w-6" />} />
      <ModalFAQ open={showFaq} setOpen={setShowFaq} icon={<QuestionIcon className="w-6" />} />
      <ModalInstruction
        open={showInstruction}
        setOpen={setShowInstruction}
        icon={<InfoCircle className="w-6" />}
      />
      <div className="text-center bg-home min-h-dvh flex flex-col justify-between">
        {width > 768 ? (
          <>
            <div className="grid grid-cols-2 items-center relative">
              <div className="absolute inset-0 min-w-1/3 max-w-[28%] mx-auto mt-[11%] z-10">
                <div className="bg-gradient-to-b from-transparent-orange-20 via-transparent-red-20 to-transparent-red-20 text-white rounded-xl backdrop-blur-md  px-4 pt-4 pb-4">
                  <div className="flex flex-col justify-between min-h-[12rem]">
                    <a
                      href="http://wa.me/6281110500515?text=%23NyambelJadiUntung"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="bg-gradient-to-b from-dark-green to-base-green text-white border border-dark-green rounded-xl w-full flex items-center justify-center gap-4 font-semibold p-2.5 mt-4"
                    >
                      <WhatsappIcon />
                      <p>MASUKKAN KODE UNIKMU DISINI</p>
                    </a>
                    {/* <div className="leading-tight">
                      <p className="font-bold text-lg">PESERTA TELAH MENANG</p>
                      <p className="text-[4rem] font-bold">1,342</p>
                    </div> */}

                    <div>
                      <p className="font-bold text-lg mb-3">WAKTU TERSISA</p>
                      <CountdownComponent />
                    </div>
                  </div>
                </div>
              </div>
              <img src={imageUrl.landingImage} alt="Img" className="-mt-5 w-[80%]" />
              <div className="justify-self-end">
                <div className="w-[70%] justify-self-end">
                  <img src={imageUrl.logo} className="w-24 mb-8" alt="ABC Logo" />
                  <img src={imageUrl.textNyambel} className="w-[90%]" alt="Nyambel jadi untung" />
                  <h2 className="text-[#FFB200] font-extrabold text-3xl text-start px-2 drop-shadow-md mb-1">
                    TOTAL HADIAH SENILAI
                  </h2>
                  <h2 className="text-white font-extrabold text-4xl text-start px-2 drop-shadow-md relative">
                    RATUSAN JUTA!<span className="text-2xl absolute top-0">*</span>
                  </h2>
                </div>
              </div>
            </div>
            <div className="mt-5">
              <div className="trapezoid"></div>
              <div className="bg-white flex flex-col gap-4 items-center pb-10 h-full">
                <div className="grid grid-cols-3 gap-4 w-[75%]">
                  <button
                    className="bg-gradient-to-b from-red-orange to-orange text-white border border-red-orange rounded-xl w-full flex items-center justify-between font-semibold p-2.5"
                    onClick={() => setShowInstruction(true)}
                  >
                    <div className="flex items-center gap-3">
                      <div className="rounded-full p-2 bg-gradient-to-b to-red-orange from-orange">
                        <InfoCircle />
                      </div>
                      <p>CARA IKUTAN</p>
                    </div>
                    <ChevronIcon />
                  </button>
                  <button
                    className="bg-gradient-to-b from-red-orange to-orange text-white border border-red-orange rounded-xl w-full flex items-center justify-between font-semibold p-2.5"
                    onClick={() => setShowFaq(true)}
                  >
                    <div className="flex items-center gap-3">
                      <div className="rounded-full p-2 bg-gradient-to-b to-red-orange from-orange">
                        <QuestionIcon />
                      </div>
                      <p>FAQ</p>
                    </div>
                    <ChevronIcon />
                  </button>
                  <button
                    className="bg-gradient-to-b from-red-orange to-orange text-white border border-red-orange rounded-xl w-full flex items-center justify-between font-semibold p-2.5"
                    onClick={() => setShowTnc(true)}
                  >
                    <div className="flex items-center gap-3">
                      <div className="rounded-full p-2 bg-gradient-to-b to-red-orange from-orange">
                        <AnnotationIcon />
                      </div>
                      <p className="text-start">
                        SYARAT & <br />
                        KETENTUAN
                      </p>
                    </div>
                    <ChevronIcon />
                  </button>
                </div>
                <div className="flex gap-6 text-monochrome-300 mb-8">
                  <a
                    href="https://www.facebook.com/p/ABC-Ahlinya-Buat-Cita-Rasa-61559688448995"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FacebookIcon />
                  </a>
                  <a
                    href="https://www.instagram.com/abc_ahlinyabuatcitarasa/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <InstagramIcon />
                  </a>
                  <WhatsappIcon />
                  <a
                    href="https://www.tiktok.com/@abc_ahlinyabuatcitarasa"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <TiktokIcon />
                  </a>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="flex flex-col items-center pt-8">
            <img src={imageUrl.logo} className="w-20" alt="ABC Logo" />
            <div className="flex items-center">
              <img src={imageUrl.landingImageMobile} alt="Img" className="w-[50%]" />
              <div className="leading-tight">
                <img src={imageUrl.textNyambel} className="w-[80%]" alt="Nyambel jadi untung" />
                <div className="">
                  <h2 className="text-[#FFB200] font-extrabold text-[14px] text-start px-1 drop-shadow-md">
                    TOTAL HADIAH SENILAI
                  </h2>
                  <h2 className="text-white font-extrabold text-base text-start px-1 drop-shadow-md relative">
                    RATUSAN JUTA!<span className="text-[10px] absolute top-0">*</span>
                  </h2>
                </div>
              </div>
            </div>
            <div className="inset-0 min-w-1/3 max-w-[90%] mx-auto mt-8">
              <div className="bg-gradient-to-b from-transparent-orange-20 via-transparent-red-20 to-transparent-red-20 text-white rounded-xl backdrop-blur-md  px-4 py-4">
                <div className="flex flex-col gap-4">
                  <a
                    href="http://wa.me/6281110500515?text=%23NyambelJadiUntung"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="bg-gradient-to-b from-dark-green to-base-green text-white border border-dark-green rounded-xl w-full flex items-center justify-center gap-2 font-semibold p-2.5 mt-4"
                  >
                    <WhatsappIcon />
                    <p>MASUKKAN KODE UNIKMU DISINI</p>
                  </a>
                  {/* <div className="leading-tight">
                    <p className="font-bold text-md">PESERTA TELAH MENANG</p>
                    <p className="text-[3rem] font-extrabold">1,342</p>
                  </div> */}
                  <div>
                    <p className="font-bold text-md mb-3">WAKTU TERSISA</p>
                    <CountdownComponent />
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="trapezoid"></div> */}
            <div className="from-red-orange bg-gradient-to-b via-white to-white flex justify-center h-full pt-8 w-full">
              <div className="grid grid-cols-3 gap-4 w-[90%]">
                <button
                  className="bg-gradient-to-b from-red-orange to-orange text-white border border-red-orange rounded-xl w-full flex items-center justify-between font-semibold p-2.5"
                  onClick={() => setShowInstruction(true)}
                >
                  <div className="flex flex-col items-center gap-3">
                    <div className="rounded-full p-2 bg-gradient-to-b to-red-orange from-orange">
                      <InfoCircle />
                    </div>
                    <p className="text-[12px]">CARA IKUTAN</p>
                  </div>
                </button>
                <button
                  className="bg-gradient-to-b from-red-orange to-orange text-white border border-red-orange rounded-xl w-full flex items-center justify-between font-semibold p-2.5"
                  onClick={() => setShowFaq(true)}
                >
                  <div className="flex flex-col items-center gap-3 w-full">
                    <div className="rounded-full p-2 bg-gradient-to-b to-red-orange from-orange">
                      <QuestionIcon />
                    </div>
                    <p className="text-[12px]">FAQ</p>
                  </div>
                </button>
                <button
                  className="bg-gradient-to-b from-red-orange to-orange text-white border border-red-orange rounded-xl w-full flex items-center justify-between font-semibold p-2.5"
                  onClick={() => setShowTnc(true)}
                >
                  <div className="flex flex-col items-center gap-3">
                    <div className="rounded-full p-2 bg-gradient-to-b to-red-orange from-orange">
                      <AnnotationIcon />
                    </div>
                    <p className="text-center text-[12px]">
                      SYARAT & <br />
                      KETENTUAN
                    </p>
                  </div>
                </button>
              </div>
            </div>
            <div className="bg-white flex flex-col items-center">
              <div className="bg-black text-white w-[80%] text-center my-8 px-8 py-4 rounded-xl">
                <p>
                  Hati-hati penipuan. Promo tidak dipungut biaya apapun. Hubungi hanya WhatsApp ABC
                  <br />
                  +62-811-1050-0515.
                </p>
              </div>
              <div className="flex gap-6 text-monochrome-300 mb-8">
                <a
                  href="https://www.facebook.com/p/ABC-Ahlinya-Buat-Cita-Rasa-61559688448995"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FacebookIcon />
                </a>
                <a
                  href="https://www.instagram.com/abc_ahlinyabuatcitarasa/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <InstagramIcon />
                </a>
                <WhatsappIcon />
                <a
                  href="https://www.tiktok.com/@abc_ahlinyabuatcitarasa"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <TiktokIcon />
                </a>
              </div>
            </div>
          </div>
        )}
      </div>
      {width > 768 && (
        <div className="fixed bottom-0 bg-black w-full text-white text-center py-3">
          <p>
            Hati-hati penipuan. Promo tidak dipungut biaya apapun. Hubungi hanya WhatsApp ABC
            +62-811-1050-0515.
          </p>
        </div>
      )}
    </>
  );
};

export default Details;

import React from "react";
import { ReactComponent as FacebookIcon } from "../assets/fb.svg";
import { ReactComponent as InstagramIcon } from "../assets/instagram.svg";
import { ReactComponent as TiktokIcon } from "../assets/tiktok.svg";
import { ReactComponent as WhatsappIcon } from "../assets/wa.svg";
import { useNavigate } from "react-router-dom";
import useWindowSize from "../hooks/useWindowSize";

const Landing = () => {
  const { width } = useWindowSize();
  const imageUrl = {
    landingImage: "https://cdn.kar-tini.id/sambal_05f62e04f5.png",
    logo: "https://cdn.kar-tini.id/logo_7593430a7a.png",
    landingText: "https://cdn.kar-tini.id/landing_text_c575679ed5.png",
    landingImageMobile: "https://cdn.kar-tini.id/sambal_mobile_2e9bd85834.png",
    landingTextMobile1: "https://cdn.kar-tini.id/text_1_bdd9e95838.png",
    landingTextMobile2: "https://cdn.kar-tini.id/text_2_6670c92cb4.png",
    textNyambel: "https://cdn.kar-tini.id/nyambel_7c24ebff79.png",
  };
  const navigate = useNavigate();
  return (
    <>
      <div className="App bg-home min-h-dvh cursor-pointer" onClick={() => navigate("/details")}>
        {width > 768 ? (
          <div className="grid grid-cols-2 items-center">
            <img src={imageUrl.landingImage} alt="Img" className="w-[90%]" />
            <div className="justify-self-center">
              <div className="w-[90%] justify-self-center">
                <img src={imageUrl.logo} className="w-24 mb-8" alt="ABC Logo" />
                <img src={imageUrl.textNyambel} className="w-[90%]" alt="Nyambel jadi untung" />
                <div className="px-2">
                  <p className="text-2xl text-start font-extrabold text-white leading-6">
                    PROGRAM AKAN DIMULAI
                  </p>
                  <p className="text-2xl text-justify font-extrabold text-white leading-6 w-[90%]">
                    PADA
                    <span className="text-3xl text-justify font-extrabold text-orange">
                      1 JANUARI 2025
                    </span>
                  </p>
                  <p className="text-lg text-start font-bold text-white mt-3 w-[90%]">
                    IKUTI TERUS DI MEDIA SOSIAL ABC!
                  </p>
                </div>
                <div className="flex gap-6 justify px-2 text-white mt-8">
                  <a
                    href="https://www.facebook.com/p/ABC-Ahlinya-Buat-Cita-Rasa-61559688448995"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FacebookIcon />
                  </a>
                  <a
                    href="https://www.instagram.com/abc_ahlinyabuatcitarasa/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <InstagramIcon />
                  </a>
                  <WhatsappIcon />
                  <a
                    href="https://www.tiktok.com/@abc_ahlinyabuatcitarasa"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <TiktokIcon />
                  </a>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex flex-col items-center py-8">
            <img src={imageUrl.logo} className="w-20" alt="ABC Logo" />
            <img src={imageUrl.landingTextMobile1} alt="ABC Logo" className="w-[80%] -mt-2" />
            <img src={imageUrl.landingImageMobile} alt="Img" className="w-[80%]" />
            <p className="text-2xl text-start font-extrabold text-white leading-6">
              PROGRAM AKAN DIMULAI
            </p>
            <p className="text-2xl text-justify font-extrabold text-white leading-6 w-[90%]">
              PADA
              <span className="text-3xl text-justify font-extrabold text-orange">
                1 JANUARI 2025
              </span>
            </p>
            <p className="text-lg text-center font-bold text-white mt-3 w-[90%]">
              IKUTI TERUS DI MEDIA SOSIAL ABC!
            </p>
            <div className="flex gap-6 text-white mt-8">
              <a
                href="https://www.facebook.com/p/ABC-Ahlinya-Buat-Cita-Rasa-61559688448995"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FacebookIcon />
              </a>
              <a
                href="https://www.instagram.com/abc_ahlinyabuatcitarasa/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <InstagramIcon />
              </a>
              <WhatsappIcon />
              <a
                href="https://www.tiktok.com/@abc_ahlinyabuatcitarasa"
                target="_blank"
                rel="noopener noreferrer"
              >
                <TiktokIcon />
              </a>
            </div>
            <div className="bg-black text-white w-[80%] text-center my-8 px-8 py-4 rounded-xl">
              <p>
                Hati-hati penipuan. Promo tidak dipungut biaya apapun. Hubungi hanya WhatsApp ABC
                <br />
                +62-811-1050-0515.
              </p>
            </div>
          </div>
        )}
      </div>
      {width > 768 && (
        <div className="fixed bottom-0 bg-black w-full text-white text-center py-3">
          <p>
            Hati-hati penipuan. Promo tidak dipungut biaya apapun. Hubungi hanya WhatsApp ABC
            +62-811-1050-0515.
          </p>
        </div>
      )}
    </>
  );
};

export default Landing;
